import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import Navbar from '../layouts/navbar'
import Loader from '../layouts/loader';
import { LanguageProvider } from './languageContext';

const DashboardLayout = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('ref');
    let decodedRef
    if (token) {
      decodedRef = decodeURIComponent(token);
      decodedRef = JSON.parse(decodedRef);
      setAuthToken(decodedRef.token);
      localStorage.setItem('authToken', decodedRef.token);
      sessionStorage.setItem("userDetail", JSON.stringify(decodedRef));
      // Optionally redirect to the initial route after saving data
      window.history.replaceState({}, document.title, window.location.pathname);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, []);

  return (
    <>
    {loader ? <Loader/> :
    <Navbar children={children} showFooter={false}>
            <Outlet/>
        </Navbar>
   }
    </>
  );
};

export default DashboardLayout;