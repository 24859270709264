import React, { useEffect, useState, useRef } from 'react';
import CourseContent from '../courseContent/courseContent';
import Video from '../videoModule/video';
import Reading from '../readingModule/reading';
import Quiz from '../quizModule/quiz';
import Sidebar from '../../layouts/sidebar';
import './dashboard.css';
import { useParams, useNavigate} from 'react-router-dom';
import apiService from '../../../services/apiService';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';
import CourseCompletedModal from '../modal/courseCompletedModal';


const Dashboard = () => {
  const [courseDetails, setCourseDetails] = useState([])
  const [isPass,setIsPass] = useState(false)
  // const { id } = useParams()
  // const history = useHistory()
  const [currentModule, setCurrentModule] = useState({});
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [is_completed,setIsComleted]= useState(false);
  const { id } = useParams()
  const navigate = useNavigate();

  const handleModuleClick = (id) => {
    let user_details = sessionStorage.getItem('user_details')
    setCurrentModule(id);
    const token = apiService.getAuthToken()
    apiService.getLmsv1(`getModuleContentStatus?module_id=${id?._id}&user_id=${JSON.parse(user_details)?.uid}`,token).then(data => {
      if (data?.status) {
        setCourseDetails({
          ...courseDetails,
          moduleContent: data?.data
        });
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      CustomToast.error(error)
    })

    setSelectedModuleId(id?._id);
  };

  const startModule = (module) => {
    let user_details = sessionStorage.getItem('user_details')
    let data = {
      "common_module_id": module?.common_module_id,
      "user_id": JSON.parse(user_details)?.uid,
      "course_meta_id": module?.course_meta_id
    }
    setCurrentModule(id);
    const token = apiService.getAuthToken()
    apiService.postLms(`startModule`, data,token).then(data => {
      if (data?.status) {
        setCourseDetails({
          ...courseDetails,
          moduleContent: data?.data?.moduleContent
        });
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      CustomToast.error(error)
    })

    setSelectedModuleId(module._id);
  };

  const getCourseDetails = (user_details, courseMetaId) => {
    const token = apiService.getAuthToken()
    return apiService.getLmsv1(`getModuleStatus?course_meta_id=${courseMetaId || id}&user_id=${user_details?.uid}`,token)
  }

  useEffect(() => {
    // Add event listener for beforeunload
    const handleBeforeUnload = () => {
        // Set a flag in sessionStorage indicating the page is being refreshed
        sessionStorage.setItem('refreshFlag', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Cleanup function to remove the event listener
    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
}, []); // Empty dependency array to run effect only once on component mount

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let user_details = sessionStorage.getItem('user_details')
    if (user_details) {
      user_details = JSON.parse(user_details)
    }
    const refreshFlag = sessionStorage.getItem('refreshFlag');
    if (refreshFlag === 'true') {
      setLoader(true);
      const courseMetaId = localStorage.getItem("courseMetaId")
      getCourseDetails(user_details, courseMetaId).then((data) => {
        if(data?.data?.moduleContent?.questions && (data?.data?.moduleContent?.course_test_type == 'end_line' || data?.data?.moduleContent?.course_test_type == 'base_line')) {
          data.data.moduleContent.questions = shuffleArray(data?.data?.moduleContent?.questions)
        }
        setSelectedModuleId(data?.data?.moduleContent?.module_id);
        setCourseDetails(data?.data)
        setLoader(false);
      }).catch((error) => {
        setLoader(false);
        CustomToast.error(error);
      })
      sessionStorage.setItem('refreshFlag', 'false');
    } else {
      setLoader(true);
      getCourseDetails(user_details).then((data) => {
        if(data?.data?.moduleContent?.questions) {
          data.data.moduleContent.questions = shuffleArray(data?.data?.moduleContent?.questions)
        }
        setSelectedModuleId(data?.data?.moduleContent?.module_id);
        setCourseDetails(data?.data)
        setLoader(false);
      }).catch((error) => {
        setLoader(false);
        CustomToast.error(error);
      })
    }

  }, [])

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const updateLmsModule = (params,type, value, generalTest) => {
    let user_details = sessionStorage.getItem('user_details')
    let data = {
      "common_module_id": courseDetails?.moduleContent?.common_module_id,
      "user_id": JSON.parse(user_details)?.uid,
      "course_meta_id": courseDetails?.moduleContent?.course_meta_id,
      "course_id": courseDetails?.course_id || ""
    }

    if(type=='test' && value){
      data.questions= Array.isArray(params)?params:[params]
      data["course_test_type"] = courseDetails?.moduleContent?.course_test_type || ""
      data["test_skipable"] = courseDetails?.moduleContent?.test_skipable || false
      data["test_tag"] = courseDetails?.moduleContent?.test_tag || ""
      data["module_type"] = "test"
    }
    const token = apiService.getAuthToken()
    return apiService.putLms(`updateLmsModule`, data,token).then(data=>{
      if (data?.status) {
        setIsPass(true)
        if(data?.data && data?.data?.course_status =="completed"){
          setIsComleted(true)
          return
        }
        // handle next module call
      // if(generalTest ==="G") {
      //   return {
      //     ...courseDetails,
      //     moduleContent: data?.data?.moduleContent
      //   }
      // } else {
      //   if(data?.data?.moduleContent?.is_completed) {
      //     setCourseDetails({
      //     ...courseDetails,
      //     moduleContent: data?.data?.moduleContent
      //   });
      //   setSelectedModuleId(data?.data?.moduleContent?.module_id)
      //   } else {
      //     let user_details = sessionStorage.getItem('user_details')
      //     if (user_details) {
      //       user_details = JSON.parse(user_details)
      //     }
      //     getCourseDetails(user_details).then((data) => {
      //       setCourseDetails(data?.data)
      //       setSelectedModuleId(data?.data?.moduleContent?.module_id);
      //       setLoader(false);
      //     }).catch((error) => {
      //       setLoader(false);
      //       CustomToast.error(error);
      //       return error
      //     })
      //   }
      //   // navigate(`/learning_space/course_modules/${data?.data?.moduleContent?.module_id}`); 
      // }
      // end next module

      if(data?.data?.moduleContent?.is_completed) {
        setCourseDetails({
        ...courseDetails,
        moduleContent: data?.data?.moduleContent
      });
      setSelectedModuleId(data?.data?.moduleContent?.module_id)
      } else {
        setLoader(true);
        let user_details = sessionStorage.getItem('user_details')
        if (user_details) {
          user_details = JSON.parse(user_details)
        }
        getCourseDetails(user_details).then((data) => {
          if(data?.data?.moduleContent?.questions && (data?.data?.moduleContent?.course_test_type == 'end_line' || data?.data?.moduleContent?.course_test_type == 'base_line')) {
            data.data.moduleContent.questions = shuffleArray(data?.data?.moduleContent?.questions)
          }
          setCourseDetails(data?.data)
          setSelectedModuleId(data?.data?.moduleContent?.module_id);
          setLoader(false);
        }).catch((error) => {
          setLoader(false);
          CustomToast.error(error);
          return error
        })
      }

      // let user_details = sessionStorage.getItem('user_details')
      // if (user_details) {
      //   user_details = JSON.parse(user_details)
      // }
    // setLoader(true);
    // getCourseDetails(user_details).then((data) => {
    //   setCourseDetails(data?.data)
    //   console.log();
    //   setSelectedModuleId(data?.data?.moduleContent?.module_id);
    //   setLoader(false);
    // }).catch((error) => {
    //   setLoader(false);
    //   CustomToast.error(error);
    //   return error
    // })
      }
    }).catch(error => {
      setLoader(false);
      CustomToast.error(error)
    })
  }

  return (
    <div className='nosidebar-page__main bg-white lms'>
      {courseDetails && (
        <div style={{ display: 'flex' }}>
          {loader ? <Loader />
            : <>
              <Sidebar modules={courseDetails} onModuleClicked={handleModuleClick} onStartModule={startModule} selectedModule={courseDetails?.moduleContent} selectedModuleId={selectedModuleId} />
              
              {is_completed&&<CourseCompletedModal className='fw-bold font-26 mb-4' setIsComleted={setIsComleted} course_id={courseDetails.course_id} course_name={courseDetails?.title}></CourseCompletedModal>}
              <CourseContent selectedModule={courseDetails?.moduleContent}>
                <h1 className='fw-bold font-26 mb-4'>{courseDetails?.moduleContent?.title}</h1>
                {/* {courseDetails?.moduleContent?.type == "video" ? <Video moduleContent={courseDetails?.moduleContent} updateLmsModule={updateLmsModule} /> : courseDetails?.moduleContent?.type == "test" ? <Quiz moduleContents={courseDetails?.moduleContent} courseDetails={courseDetails} updateLmsModule={updateLmsModule} isPass={isPass} /> : courseDetails?.moduleContent?.type == "content" ? <Reading moduleContent={courseDetails?.moduleContent} updateLmsModule={updateLmsModule} /> : null} */}
                {courseDetails?.moduleContent?.type == "video" ? <Video moduleContent={courseDetails?.moduleContent} updateLmsModule={updateLmsModule} /> : courseDetails?.moduleContent?.type == "test" ? <Quiz moduleContents={courseDetails?.moduleContent} courseDetails={courseDetails} updateLmsModule={updateLmsModule} isPass={isPass} /> : courseDetails?.moduleContent?.type == "content" ? <Reading moduleContent={courseDetails} updateLmsModule={updateLmsModule} /> : null}
                {/* {currentModule.content} */}
              </CourseContent>
            </>
          }
        </div>
      )}
    </div>
  );
};

export default Dashboard;





