import Routes from "./appRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <>
       <ToastContainer />
       <Routes/>
    </>
  );
}

export default App;
