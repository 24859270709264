import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const Loader2 = () => {
  return (
    <div className="loader-container d-flex justify-content-center align-items-center">
      <div className='w-10'>
       <div className="lds-facebook"><div></div><div></div><div></div></div>
        <h3 className="primary_color font-26 ms-2 fw-bold">Loading...</h3>
      </div>
    </div>
  );
};

export default Loader2;