import React, { useEffect, useState } from 'react';
import Details from './details';
import Course from './course';
import Loader from '../../layouts/loader';
import apiService from '../../../services/apiService'
import { LanguageProvider } from '../../shared/languageContext';

const LearningSpace = () => {
    const [userInfo, setUserInfo] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                let user = JSON.parse(sessionStorage.getItem("user_details"));
                if(user) {
                    setUserInfo(user);
                } else {
                    const token = apiService.getAuthToken()
                    const result = await apiService.get('get-org-user-info', token);
                    sessionStorage.setItem('user_details', JSON.stringify(result.data))
                    if(result && result.status == "success") {
                        setUserInfo(result.data);
                    }
                }
            } catch (error) {
                setLoader(false);
                console.error("Error fetching user details:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchUserDetails();
    }, []);
    //console.log(Object.keys(userInfo).length)
    const shouldReloadDetails = !userInfo || Object.keys(userInfo).length == 0
    return (
        <>
            {loader ? <Loader /> :
                <div className='nosidebar-page__main' style={{minHeight: '100vh'}}>
                    <div className="dashboard">
                        <div className='container'>
                            {((userInfo?.gender && userInfo?.gender != "undefined") && (userInfo?.age_group && userInfo?.age_group != "undefined")) ? null : <Details userInfo={userInfo} />}
                               <Course />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default LearningSpace;