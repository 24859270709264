import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../layouts/sidebar';

const PublicRoutesLayout = ({children}) => {
  return (
    <>
        <Sidebar children={children}>
            <Outlet/>
        </Sidebar>
    </>
  )
}

export default PublicRoutesLayout;