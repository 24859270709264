import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from '../../assets/locales/en/en.json';
import hiTranslation from '../../assets/locales/hi/hi.json';

// Configure i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Bind react-i18next to i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      hi: {
        translation: hiTranslation,
      },
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already sanitizes output
    },
  });

export default i18n;