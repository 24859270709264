import React, {useState} from 'react';

const CourseContent = ({ children, selectedModule }) => { 

    const renderBreadcrumb = () => {
        if (!selectedModule) {
          return null;
        }
    
        const { title, type, content } = selectedModule;
    
        return (
          <>
          <div className='breadcrumb text-capitalize'>
            {/* <span className='breadcrumb-item primary_color fw-bold me-2'>Courses</span> {'>'} <span className='current-module ms-2'>{type} : {title} {content.props.name}</span> */}
          </div>
          </>
        );
      };

  return  <div className={`dashboard w-100`}>
  {renderBreadcrumb()}
  <div>{children}</div>
</div>;
  
};

export default CourseContent;

