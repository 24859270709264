import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CustomToast = {
  success: (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000 // Set the duration (e.g., 3000 milliseconds for 3 seconds)
    });
  },
  error: (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000 // Set the duration (e.g., 3000 milliseconds for 3 seconds)
    });
  },
  warn: (message) => {
    toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000 // Set the duration (e.g., 3000 milliseconds for 3 seconds)
    });
  },
  info: (message) => {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000 // Set the duration (e.g., 3000 milliseconds for 3 seconds)
    });
  }
};

export default CustomToast;