import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation();

    return (
        <div className='footer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <p className=''>
                            <a href='/privacy' target="_blank" className='font-20 text-left text-black text-decoration-none'>{t('footer_page_privacy_policy')}</a>
                            <a href='/term' target="_blank" className='font-20 ms-4 text-black text-decoration-none'>{t('footer_page_terms_and_conditions')}</a>
                        </p>
                        <div className='social-media mt-4'>
                            <a href="https://www.linkedin.com/company/inclusional/" target="_blank" className='social-link'>
                                <img src="/images/linkedin.png" className='social-icons'></img>
                            </a>
                            <a href="https://twitter.com/_inclusional/" target="_blank" className='social-link'>
                                <img src="/images/twitter.png" className='social-icons'></img>
                            </a>
                            <a href="https://www.instagram.com/inclusional/" target="_blank" className='social-link'>
                                <img src="/images/instagram.png" className='social-icons'></img>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100095283414576&amp;is_tour_dismissed=true" target="_blank" className='social-link'>
                                <img src="/images/facebook.png" className='social-icons'></img>
                            </a>
                        </div>
                        <p className='mt-4 mb-0 footer-copyright'>{t('footer_page_copyright')}</p>
                    </div>

                    <div className='col-md-3 mb-lg-0 mb-3'>
                        <p className='font-20 fw-bold ps-lg-3 ms-lg-3 ps-0 ms-0'>{t('footer_page_contact_us')}</p>
                        <a href="mailto:support@inclusional.com" className="font-20 ps-lg-3 ms-lg-3 ps-0 ms-0 text-black text-decoration-none">
                            <img src="/images/email.png" className="footer-email" />
                            support@inclusional.com
                        </a>
                    </div>

                    <div className='col-md-2'>
                        <p className='font-20 fw-bold'>{t('footer_page_quick_link')}</p>
                        <a href="/" className='font-20 text-black text-decoration-none'>{t('footer_page_watch_tutorial')}</a>
                    </div>

                    <div className='col-md-4'>
                        <img src="/images/footer-logo.png" className='footer-logo'></img>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Footer;