import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLanguages, setSelectedLanguages] = useState('');

  const updateLanguage = (lang) => {
    setSelectedLanguages(lang);
    // Additional logic for updating language if needed
  };

  return (
    <LanguageContext.Provider value={{ selectedLanguages, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};