import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Loader = () => {
  return (
    <div className="loader-container d-flex justify-content-center align-items-center">
      <div className="lds-facebook"><div></div><div></div><div></div></div>
     {/* <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div> */} 
    </div>
  );
};

export default Loader;