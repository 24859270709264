import React, { useState } from "react";
import './courseModules.css';
import Modal from "react-modal";
import InsightModal from "../modal/insightModal";


const CourseModules = () => {

    const [insightModalIsOpen, setInsightModalIsOpen] = useState(false);

    const openInsightModal = () => {
        setInsightModalIsOpen(true);
    };

    const closeInsightModal = () => {
        setInsightModalIsOpen(false);
    };

    return (
        <>
            <div className="d-flex nosidebar-page__main bg-white">
                <div id="moduleSidebar" className="sidebar-open course-module-sidebar bg-white">
                    <p className="py-2"><a href="#" className="btn-toggle-nav-open font-18 fw-bold primary_color text-decoration-none mb-4">
                        <span className="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 33 33" fill="none">
                                <path d="M6.99958 17.6668L12.3329 23.0001L10.4663 24.8934L1.90625 16.3334L10.4663 7.77344L12.3329 9.66677L6.99958 15.0001H28.3329V17.6668H6.99958ZM28.3329 8.33344V11.0001H14.9996V8.33344H28.3329ZM28.3329 21.6668V24.3334H14.9996V21.6668H28.3329Z" fill="#146B90" />
                            </svg>
                        </span>Hide Menu</a></p>
                    <h1 className="font-20 fw-bold pb-2">Safe workplaces</h1>
                    <hr />
                    <div className="course-modules">

                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <span className="fw-bold font-20">Module Name 1</span>
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className="submodule"><span className="sidetab-img">
                                            <img src="/images/video.svg" alt="youtube" /></span>Sub Module 1<br /><span className="text-dark-gray">5 mins</span>
                                        </p>
                                        <p className="submodule" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2"><span className="sidetab-img"> <img src="/images/stack.svg" alt="youtube" /></span>Sub Module 2<br /><span className="text-dark-gray">3 sub sub modules</span>
                                            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <hr />
                                                    <p className="submodule"><span className="sidetab-img"> <img src="/images/video.svg" alt="youtube" /></span>Sub Module 1<br /><span className="text-dark-gray">5 mins</span>
                                                    </p>
                                                    <p className="submodule"><span className="sidetab-img"> <img src="/images/notes.svg" alt="youtube" /></span>Sub Module 2<br /><span className="text-dark-gray">5 min</span></p>
                                                    <p className="submodule"><span className="sidetab-img"> <img src="/images/notes.svg" alt="youtube" /></span>Sub Module 3<br /><span className="text-dark-gray">8 mins</span></p>
                                                </div>
                                            </div></p>
                                        <p className="submodule"><span className="sidetab-img"> <img src="/images/notes.svg" alt="youtube" /></span>Sub Module 3<br /><span className="text-dark-gray">8 mins</span></p>
                                        <p className="submodule"><span className="sidetab-img"> <img src="/images/video.svg" alt="youtube" /></span>Sub Module 4<br /><span className="text-dark-gray">5 Questions</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <span className="fw-bold font-20">Module Name 2</span>

                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <span className="fw-bold font-20">Module Name 3</span>

                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='learning-course-content p-4'>
                    <nav aria-label="breadcrumb"><ol className="breadcrumb"><li className="breadcrumb-item"><a href="#">Courses</a></li><li className="breadcrumb-item primary_color active" aria-current="page">Video : Module Name</li></ol></nav>
                    <div className="learning_course_banner position-relative">
                        <img src="/images/learning_banner.png" alt="banner" className="w-100" />
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 ms-0">
                            <h3 className="fw-bold font-26 mb-4">Module Name</h3>
                            <p className="transcript fw-bold mb-5 primary_color">Transcript</p>
                            <div className="module-content">
                                <div className="d-flex">
                                    <div className="video-time"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                        <path d="M12.1413 6.07554C13.0791 6.5783 13.0791 7.923 12.1413 8.42576L2.29657 13.7036C1.40834 14.1798 0.333251 13.5364 0.333251 12.5285L0.333252 1.97277C0.333252 0.96494 1.40834 0.321465 2.29658 0.797657L12.1413 6.07554Z" fill="#787878" />
                                    </svg></span>0.01</div>
                                    <p className="text-justify mb-4 ms-4">Morem <span className="course-module-tooltip">ipsum <span className="tooltiptext">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. </span></span>
                                        dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class taciti sociosqu ad litora torquent per aptent nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                                </div>
                                <div className="d-flex">
                                    <div className="video-time"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                        <path d="M12.1413 6.07554C13.0791 6.5783 13.0791 7.923 12.1413 8.42576L2.29657 13.7036C1.40834 14.1798 0.333251 13.5364 0.333251 12.5285L0.333252 1.97277C0.333252 0.96494 1.40834 0.321465 2.29658 0.797657L12.1413 6.07554Z" fill="#787878" />
                                    </svg></span>0.01</div>
                                    <p className="text-justify mb-4 ms-4">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class taciti sociosqu ad litora torquent per aptent nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                                </div>
                                <div className="d-flex">
                                    <div className="video-time"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                        <path d="M12.1413 6.07554C13.0791 6.5783 13.0791 7.923 12.1413 8.42576L2.29657 13.7036C1.40834 14.1798 0.333251 13.5364 0.333251 12.5285L0.333252 1.97277C0.333252 0.96494 1.40834 0.321465 2.29658 0.797657L12.1413 6.07554Z" fill="#787878" />
                                    </svg></span>0.01</div>
                                    <div className="insight ms-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="font-18 fw-bold primary_color mb-0">Insight</h4>
                                            <a onClick={openInsightModal}> <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 37 37" fill="none">
                                                <path d="M18.6667 0.345703C8.81791 0.345703 0.759766 8.40384 0.759766 18.2527C0.759766 28.1015 8.81791 36.1597 18.6667 36.1597C28.5156 36.1597 36.5737 28.1015 36.5737 18.2527C36.5737 8.40384 28.5156 0.345703 18.6667 0.345703ZM22.2481 25.4155V20.0434C15.7837 20.0434 11.1637 22.6041 7.92256 27.2062C9.21186 20.6343 13.1872 14.1878 22.2481 12.8806V7.50849L31.2016 16.462L22.2481 25.4155Z" fill="#146B90" />
                                            </svg></a></div>

                                        <Modal
                                            isOpen={insightModalIsOpen}
                                            onRequestClose={closeInsightModal}
                                            contentLabel="Insight Modal">
                                            <InsightModal />
                                        </Modal>
                                        <hr />
                                        <p className="mb-0">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. </p>
                                    </div>
                                    <p className="text-justify ms-4">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class taciti sociosqu ad litora torquent per aptent nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CourseModules;