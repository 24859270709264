import React from 'react';
import { useTranslation } from 'react-i18next';

const GeneralModal = ({ isOpen, message, onRequestClose }) => {

  const { t } = useTranslation();

  const closeModal = () => {
    onRequestClose();
  }  

  return (
    <div className={`error-modal d-flex justify-content-center align-items-center text-center p-2 ${isOpen ? 'show' : ''}`}>
      <div className="modal-content">
        <div className="modal-body">
          <p>{message}</p>
        </div>
        <div className="modal-footer text-center mt-3">
          <button type="button" className="btn btn-md btn-primary py-2 mx-auto" onClick={closeModal}>
            {t('course_modules_close')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneralModal;