import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateLayout from "./components/shared/privateLayout";
import DashboardLayout from "./components/shared/dashboardLayout";
import Dashboard from "./components/pages/dashboard/dashboard";
import LearningSpace from "./components/pages/learningSpace/learningSpace";
import CourseDetails from "./components/pages/courseDetails/courseDetails";
import CourseModules from "./components/pages/courseModules/courseModules";
import PublicRoutesLayout from "./components/shared/publicRoutesLayout";
import MyProfile from "./components/pages/myProfile/myProfile";
import { LanguageProvider } from "./components/shared/languageContext";


export default function AppRoutes() {
    return (
        // <LanguageProvider>
            <Router>
            <Routes>
               <Route
                    path="/"
                    element={<DashboardLayout />}
                >   
                    <Route path='/' element={<LearningSpace />}></Route>
                    <Route path='/learning_space/course_details/:id' element={<CourseDetails />}></Route>
                    <Route path='/learning_space/course_modules/:id' element={<Dashboard />}></Route>

                </Route>

                <Route
                    path="/"
                    element={<PublicRoutesLayout />}
                >
                    
                </Route>

                <Route
                    path="/"
                    element={<PrivateLayout />}
                >

<               Route path= '/my_profile' element= {<MyProfile /> }></Route>

                </Route>

            </Routes>
        </Router>
        // </LanguageProvider>
    );
}

