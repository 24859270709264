import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const InsightModal = ({ copyText }) => {

    const [isCopied, setIsCopied] = useState(false);
    const [insightModalIsOpen, setInsightModalIsOpen] = useState(false);
    const { t } = useTranslation();

    const closeInsightModal = () => {
        setInsightModalIsOpen(false);
    };

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    };

    const handleCopyClick = () => {

        copyTextToClipboard(copyText)
            .then(() => {

                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });

    };

    return (

        <div className='d-flex flex-column justify-content-start align-items-start'>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <h3 className='font-weight-600 font-20'>{t('course_modules_insight')}</h3>
                <div>
                <a className='w-100 text-end test-decoration-none'><svg onClick={closeInsightModal}  width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                        <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                    </svg></a>
                </div>
            </div>
            <p>{t('course_modules_share_insight')}</p>
            <div className='d-flex justify-content-between align-items-center w-100 mt-3 social-icons'>
                <div className='text-center'>
                    <img src='/images/facebook.svg' alt='facebook' />
                    <p className='pt-4'>{t('course_modules_facebook')}</p>
                </div>
                <div className='text-center'>
                    <img src='/images/whatsapp.svg' alt='whatsapp' />
                    <p className='pt-4'>{t('course_modules_whatsapp')}</p>
                </div>
                <div className='text-center'>
                    <img src='/images/x.svg' alt='x' />
                    <p className='pt-4'>X</p>
                </div>
                <div className='text-center'>
                    <img src='/images/linkedin.svg' alt='linkedin' />
                    <p className='pt-4'>{t('course_modules_linkedin')}</p>
                </div>
                <div className='text-center'>
                    <img src='/images/mail.svg' alt='instagram' />
                    <p className='pt-4'>{t('course_modules_instagram')}</p>
                </div>

            </div>
            <div className='d-flex justify-content-between align-items-center copy-link mt-4 w-100'>
                <input type="text" value={copyText} readOnly />

                <button onClick={handleCopyClick}>
                    <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                </button>
            </div>
        </div>

    )
}

export default InsightModal;